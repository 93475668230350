import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(52.000000, 50.000000)">
        {/* <path
          d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          fill="currentColor"
        />  */}
        <path
          d="M1.45-20.12L15.77 15.71L12.02 15.71Q11.37 15.71 10.97 15.38Q10.57 15.06 10.37 14.56L10.37 14.56L7.02 5.91L-9.05 5.91L-12.40 14.56Q-12.58 15.01-13.00 15.36Q-13.43 15.71-14.05 15.71L-14.05 15.71L-17.80 15.71L-3.48-20.12L1.45-20.12ZM-2.08-12.14L-7.70 2.41L5.67 2.41L0.05-12.17Q-0.50-13.52-1.03-15.54L-1.03-15.54Q-1.30-14.52-1.57-13.66Q-1.83-12.79-2.08-12.14L-2.08-12.14Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;

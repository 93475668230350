import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(0.000000, 3.000000)">
      <g transform="translate(40.000000, 48.000000)">
        <path
          d="M1.45-20.12L15.77 15.71L12.02 15.71Q11.37 15.71 10.97 15.38Q10.57 15.06 10.37 14.56L10.37 14.56L7.02 5.91L-9.05 5.91L-12.40 14.56Q-12.58 15.01-13.00 15.36Q-13.43 15.71-14.05 15.71L-14.05 15.71L-17.80 15.71L-3.48-20.12L1.45-20.12ZM-2.08-12.14L-7.70 2.41L5.67 2.41L0.05-12.17Q-0.50-13.52-1.03-15.54L-1.03-15.54Q-1.30-14.52-1.57-13.66Q-1.83-12.79-2.08-12.14L-2.08-12.14Z"
          fill="currentColor"
        />
      </g>
      <polygon
        id="Shape"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        points="39 0 0 22 0 67 39 90 78 68 78 23"
      />
    </g>
  </svg>
);

export default IconLogo;
